<template>
   <v-app>
      <v-main>
         <v-form ref="form" @submit.prevent="reset" class="d-flex justify-center align-center">
            <v-card dark color="rgba(0, 0, 0, 0.6)" width="480">
               <v-card-title>Ganti Password</v-card-title>
               <v-card-text class="px-10">
                  <v-text-field append-icon="mdi-account" label="Email" type="text" v-model="fm.email" :rules="emailRules" />
                  <v-text-field append-icon="mdi-account-key" label="Password" type="password" v-model="fm.password" :rules="pwdRules" />
                  <v-text-field append-icon="mdi-account-key" label="Password Konfirmasi" type="password" v-model="fm.retype_password" :rules="[checkPasswd]" />
               </v-card-text>
               <v-card-actions class="justify-center pb-8">
                  <v-btn outlined rounded width="120px" color="primary" type="submit" :loading="loading">submit</v-btn>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-main>
      <alert />
   </v-app>
</template>

<script>
import Alert from '../components/Alert.vue';
import config from '../config';
export default {
   components: { Alert },
   data: () => ({
      fm: {},
      emailRules: [v => !!v || 'Email harus diisi', v => /.+@.+\..+/.test(v) || 'Email tidak valid'],
      pwdRules: [v => !!v && v.length > 5 || 'Password minimal 6 karakter'],
      loading: false,
      token: null,
   }),
   methods: {
      checkPasswd(v) {
         return  v === this.fm.password || 'Password tidak sama';
      },
      reset() {
         if(!this.$refs.form.validate()) return;
         this.loading = true;
         fetch(config.api_url +'/reset_password?token='+ this.$route.query.token, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(this.fm),
         }).then(response => response.json()).then(rsl => {
            if(rsl.error) {
               this.$alert({title: 'Ooops!', text: rsl.error, type: 'warning'});
            } else {
               this.$alert({
                  type: 'success',
                  title: 'Berhasil!',
                  text: rsl.success,
                  timer: 3000,
               });
               this.$router.push('/login');
            }
         },
         e => {
            console.log('error', e);
            this.$alert('Ooops!', 'Terjadi kesalahan', 'error');
         }).finally(() => { this.loading = false });
      },
      checkPwd(v) {
         return v === this.fm.password || 'Kedua password tidak sama';
      },
   }
}
</script>
<style lang="css" scoped>
#app::before {
   content: '';
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: url("../assets/bg.jpg") no-repeat center center;
   background-size: cover;
   opacity: .5;
   will-change: transform;
   /* z-index: -1; */
}
form {height: 100%;}
</style>